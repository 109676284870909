import type { Pinia } from "pinia";
import type { Component } from "vue";
import type { LocaleMessageObject } from "vue-i18n";
import type { ImportedComponent } from "vue/types/options";

import { memoize } from "@solvari/common-fe/helpers";
import { getSentry } from "@solvari/common-fe/integrations";
import Vue from "vue";
import VueI18n from "vue-i18n";
import { createI18n } from "vue-i18n-composable";

import { getEnv } from "@/vue/utils/solvariConfig";

type VueIslandConfig = Record<string, () => Promise<ImportedComponent>>;

interface VueIslandPlugins {
  [plugin: string]: unknown;
  i18n?: VueI18n;
  pinia?: Pinia;
}

const genericI18n = memoize(() => {
  Vue.use(VueI18n);
  return createI18n({
    locale: getEnv().config.localeCode,
    silentTranslationWarn: true,
  });
});

function initVueIslands(
  components: VueIslandConfig,
  plugins: Partial<VueIslandPlugins> = {},
) {
  renderVueIslands(components, plugins as VueIslandPlugins);
  const observer = new MutationObserver(() => {
    renderVueIslands(components, plugins as VueIslandPlugins);
  });
  observer.observe(document.body, { childList: true, subtree: true });
}

function renderVueIslands(
  components: VueIslandConfig,
  plugins: VueIslandPlugins,
) {
  const rootElements =
    document.querySelectorAll<HTMLElement>("[data-component]");

  rootElements.forEach((componentContainer) => {
    const name = componentContainer.dataset.component!;
    const component = components[name];

    if (!component) {
      getSentry().captureException(
        new Error(
          `Failed to init vue island "${name}": No component with that name was found.`,
        ),
      );
      return;
    }

    try {
      renderVueIsland(componentContainer, component, plugins);
    } catch (error) {
      (error as Error).message = `Failed to init vue island "${name}": ${
        (error as Error).message
      }`;
      getSentry().captureException(error);
    }
  });
}

function renderVueIsland(
  componentContainer: HTMLElement,
  component: Component | (() => Promise<ImportedComponent>),
  plugins: VueIslandPlugins,
) {
  const i18n = plugins.i18n ?? genericI18n();
  if (componentContainer.dataset.messages) {
    i18n.mergeLocaleMessage(
      getEnv().config.localeCode,
      JSON.parse(componentContainer.dataset.messages) as LocaleMessageObject,
    );
  }

  const props = componentContainer.dataset.props
    ? (JSON.parse(componentContainer.dataset.props) as Record<string, unknown>)
    : {};

  new Vue({
    ...plugins,
    i18n,
    render: (create) =>
      create(
        component,
        {
          props: {
            ...props,
            class: componentContainer.classList,
            style: componentContainer.style,
          },
        },
        [componentContainer.innerHTML],
      ),
  }).$mount(componentContainer);
}

export { initVueIslands };
export type { VueIslandConfig };
